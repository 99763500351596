import { cookieStorageManagerSSR } from "@chakra-ui/color-mode";
import { ChakraProvider } from "@chakra-ui/provider";
import { extendTheme } from "@chakra-ui/theme-utils";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import { BuyMeACoffee } from "ui/src/components/BuyMeACoffee";
import { MultitenancyProvider } from "ui/src/context/MultitenancyContext";

// import {
//   Alert,
//   AlertIcon,
//   AlertTitle,
//   AlertDescription,
// } from "@chakra-ui/react";

import "@fontsource/space-mono/index.css";
import "@fontsource/biorhyme/index.css";
import "ui/styles/global.css";

import type { AppProps } from "next/app";
// import { useStore } from "ui";

const theme = extendTheme({
  fonts: {
    heading: "Space Mono",
    body: "Space Mono",
  },
  config: {
    initialColorMode: "system",
    useSystemColorMode: true,
  },
});

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const colorModeManager = cookieStorageManagerSSR("tlwi-dev-color-mode");

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // @ts-expect-error: gtag will exist
      window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
      });
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <ChakraProvider theme={theme} colorModeManager={colorModeManager}>
        <MultitenancyProvider>
          <Component {...pageProps} />
          <BuyMeACoffee />
        </MultitenancyProvider>
      </ChakraProvider>
      <Script src="https://sa.dennisokeeffe.com/latest.js" />
      <noscript>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="https://sa.dennisokeeffe.com/noscript.gif"
          alt=""
          referrerPolicy="no-referrer-when-downgrade"
        />
      </noscript>
    </>
  );
}

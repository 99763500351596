import React, { useEffect, useState } from "react";

interface Multitenancy {
  homeUrl: string;
}
const MultitenancyContext = React.createContext<Multitenancy | undefined>(
  undefined
);

interface MultitenancyProviderProps {}

function useGetHomeUrl() {
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  switch (true) {
    case process.env.NODE_ENV === "development":
      return "http://localhost:3000";
    case process.env.NEXT_PUBLIC_PREVIEW_URLS !== undefined:
      // We don't support preview URLs for the home page
      // due to the way we handle the home page in the
      return isSSR ? "/" : `https://${window?.location?.hostname}`;
    default:
      return `https://thelastweekin.dev`;
  }
}

/**
 * @param {MultitenancyProviderProps} props Props to pass to provider
 * @param {Multitenancy} props.value Initial state for context
 * @returns {React.ReactElement} Context Provider
 */
export function MultitenancyProvider(
  props: React.PropsWithChildren<MultitenancyProviderProps>
) {
  const homeUrl = useGetHomeUrl();

  return (
    <MultitenancyContext.Provider
      value={{
        homeUrl,
      }}
      {...props}
    />
  );
}

/**
 * Hook to access state
 *
 * @returns {Multitenancy} hook to access state and dispatch
 */
export function useMultitenancy(): Multitenancy {
  const context = React.useContext(MultitenancyContext);

  if (!context) {
    throw new Error(
      `useMultitenancy must be rendered within the MultitenancyProvider`
    );
  }

  return context;
}
